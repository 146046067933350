/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin small-view-desktop {
    @media (min-width: 810px) and (max-width: 1360px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px) and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px) and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin ultra-wide-desktop {
    @media (min-width: 1480px) {
        @content;
    }
}

@mixin wide-mid-ultra-wide-desktop {
    @media (min-width: 1280px) and (max-width: 1480px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin after-tablet {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 810px) {
        @content;
    }
}

@mixin old-mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mobile-small {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin mobile-extra-small {
    @media (max-width: 355px) {
        @content;
    }
}

@mixin standalone {

    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}