/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.FreeGiftCategoryPage {
    &-Top {
        position: relative;
        margin-bottom: 28px;

        @include desktop {
            margin-bottom: 40px;
        }

        img {
            vertical-align: top;
        }

        &Content {
            position: absolute;
            left: 14px;
            right: 14px;
            bottom: 26px;

            @include desktop {
                max-width: 732px;
                margin: 0 auto;
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &-Heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 8px;
        letter-spacing: -0.2px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            margin-bottom: 12px;
        }
    }

    &-info {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        max-width: 415px;
    }

    &-SubHeading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 22px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        gap: 24px;
        border-bottom: 1px solid $grey;
        margin: 0 16px 20px;

        &:after {
           content: attr(data-count);
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
        }
    }

    &-ShortDescription {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height: 64px;

        @include desktop {
            margin-bottom: 16px;
        }
    }

    &-Link {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        gap: 21px;
        justify-content: space-between;
        width: 100%;

        @include desktop {
            justify-content: flex-start;
            width: auto;
        }

        &:after {
            content: "";
            width: 6px;
            height: 6px;
            border-width: 1px;
            border-style: solid;
            border-color: transparent $black $black transparent;
            transform: rotate(-45deg);
        }
    }

    &-ColumnWrapper {
        // display: flex;
        margin-bottom: 2.4rem;

        @include mobile {
            display: block;
            padding: 1.2rem 0;
        }
    }
    .ContentWrapper {

        @include ultra-narrow-desktop {
            display: block;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-right: 1.8rem;

            @include mobile {
                margin-right: 0;
            }
        }
    }

    .ProductCard {
        @include desktop {
            padding: 0 72px;
        }

        &-Footer {
            flex-direction: column;
            align-items: flex-start;
        }

        &-ProductConfigurableAttributes {
            display: none;
        }

        .ProductWishlistButton {
            display: none !important;
        }

        &:not(:nth-child(2n+1)) {
            &:before {
                @include tablet {
                    content: "";
                    height: 239px;
                    border-left: 1px solid $black;
                    position: absolute;
                    left: -11px;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                }
            }
        }

        &:not(:nth-child(3n+1)) {
            &:before {
                @include narrow-desktop {
                    content: "";
                    height: 239px;
                    border-left: 1px solid $black;
                    position: absolute;
                    left: -11px;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                }
            }
        }

        &:not(:nth-child(4n+1)) {
            &:before {
                @include wide-desktop {
                    content: "";
                    height: 239px;
                    border-left: 1px solid $black;
                    position: absolute;
                    left: -11px;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                }
            }
        }
    }

    .ProductActions-Reviews {
        justify-content: flex-end;
    }
}
