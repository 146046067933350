/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --category-filter-overlay-desktop-color: #979797;
    --category-filter-overlay-selected-color: var(--primary-base-color);
    --category-filter-overlay-hover-color: var(--primary-light-color);
}

.CategoryFilterOverlay {
    &.Overlay {
        @include desktop {
            position: relative;
            width: 100%;
            opacity: 1;
            pointer-events: all;
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: visible;
            margin-bottom: 27px;
        }
    }

    @include mobile {
        &.Overlay {
            transition: transform 200ms ease-in-out;
            transform: translateY(100%);
            will-change: transform;
        }

        &-Wrapper {
            display: grid;
            grid-template-rows: min-content auto min-content;
            height: 100%;
        }
    }

    +.CategoryFilterOverlay-SeeResults {
        display: none;
    }

    &-ResetSection {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            margin-top: 14px;
            flex: 1 1 50%;
        }

        @include mobile {
            margin: 0 16px 25px;
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;

            &::-webkit-scrollbar-track {
                border-radius: 5px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar {
                height: 0;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $black;
            }
        }

        .ResetAttributes {
            display: flex;
            flex-wrap: wrap;

            @include mobile {
                flex-shrink: 0;
            }

            @include desktop {
                margin-right: 23px;
                display: contents;
            }

            .ResetAttributes-AttributeValue {
                @include desktop {
                    margin-right: 8px;
                }
            }

        }

        .ResetButton {
            @include desktop {
                margin-left: 16px;
            }

            @include mobile {
                flex-shrink: 0;
                width: auto;
            }

            &-Button {
                @include mobile {
                    padding: 0;
                    width: auto;
                }
            }
        }
    }

    &_isVisible {
        &.Overlay {
            transform: translateY(0%);
            z-index: 100;

            &:not(.Overlay_isStatic) {
                top: 0;
                height: 100%;
                padding-bottom: 0;

                @include mobile {
                    top: 0;
                    z-index: 101;
                    height: calc(100% - 49px);
                    border-bottom: 81px solid $white;
                }

                +.CategoryFilterOverlay-SeeResults {
                    @include mobile {
                        display: flex;
                        position: fixed;
                        bottom: 49px;
                        left: 0;
                        right: 0;
                        z-index: 101;
                    }
                }
            }
        }
    }

    &-NoResults {
        margin: 0;
        padding-block-start: 12px;
        padding-block-end: 0;
        padding-inline: 12px;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        @include desktop {
            padding-block-start: 0;
            padding-block-end: 12px;
            padding-inline: 0;
        }
    }

    &-Heading {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        border-bottom: 1px solid $grey;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_isContentFiltered {
            display: none;
        }
    }

    &-Close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 18px;
        right: 17px;
        z-index: 1;
    }

    &-Item {
        @include desktop {
            margin-inline-end: 12px;
        }

        &_isSelected {
            &:not(.Swatch_type_color):not(.Swatch_type_cat) {
                --category-filter-overlay-desktop-color: var(--category-filter-overlay-selected-color);
            }
        }

        &:not(.Swatch_type_color):not(.Swatch_type_cat) {
            @include desktop {
                padding: 0 12px;
                border: 1px solid var(--category-filter-overlay-desktop-color);
                color: var(--category-filter-overlay-desktop-color);
                margin-inline-end: 6px;
            }

            &:hover {
                @include desktop {
                    --category-filter-overlay-desktop-color: var(--category-filter-overlay-hover-color);
                }
            }
        }

        &_type_cat {
            &::before {
                @include desktop {
                    content: '\2022';

                    margin-inline-end: 12px;
                    margin-inline-start: 2px;
                }
            }
        }
    }

    &-Placeholder {
        padding: 12px;

        @include mobile {
            padding: 14px;
        }
    }

    &-Attributes {
        --option-margin-block: 6px;
        --option-margin-inline: 6px;

        @include mobile {
            --option-margin-block: 7px;
            --option-margin-inline: 7px;
            padding: 0 16px;
        }

        //@include desktop {
        //    --option-margin-block: 0 12px;
        //    --option-margin-inline: 0 12px;
        //
        //    .ExpandableContent:not(:last-child)  {
        //        border-block-end: 1px solid var(--expandable-content-divider-color);
        //    }
        //
        //    .ExpandableContent  {
        //        padding-block-start: 24px;
        //        padding-block-end: 12px;
        //        padding-inline: 0;
        //    }
        //}
    }

    &-Filter {
        order: 50;
    }

    &-SeeResults,
    &-ResetButton {
        @include mobile {
            inset-block-end: 0;
            width: 100%;
            inset-inline-start: 0;
            padding: 16px;
        }

        .CategoryFilterOverlay-ResetSection & {
            padding: 0;
        }
    }

    &-SeeResults {
        @include desktop {
            display: none;
        }

        @include mobile {
            background-color: var(--color-white);
            border-block-start: 1px solid var(--primary-divider-color);
            display: flex;
            flex-direction: row-reverse;
            gap: 12px;
        }

        .CategoryFilterOverlay-NoResults~& {
            @include mobile {
                display: none;
            }
        }

        .CategoryFilterOverlay-ResetButton {
            padding: 0;
        }

        .Button {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.04em;
            height: 48px;
            border: 1px solid $black;
            text-decoration: none;

            &.CategoryFilterOverlay-Button {
                border-color: $greyF0F0F0;
                color: $grey;

                &.active {
                    border-color: $black;
                    background: $black;
                    color: $white;
                }
            }
        }
    }

    &-Button.Button {
        width: 100%;
    }

    &-ResetButton {
        display: inline-block;
        vertical-align: top;

        @include desktop {
            margin-block-end: 7px;
        }

        .CategoryFilterOverlay-NoResults~& {
            @include mobile {
                display: block;
            }
        }
    }

    li {
        padding-inline-start: 0;

        &::before {
            content: none;
        }

        &:last-child {
            @include mobile {
                margin-block-end: 8px;
            }
        }
    }

    .TextPlaceholder,
    .ProductConfigurableAttributes-Placeholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductConfigurableAttributes-DropDownList {
        >.Field {
            margin: 0 0 10px;

            input {
                width: 100%;
            }
        }
    }

    .SpecialOffersFilters-ExpandableContentContent {
        .Field {
            margin: 0 0 10px;

            input {
                width: 100%;
            }
        }
    }
}