/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --category-details-background: #{$white};
}

.CategoryDetails {
    text-align: center;

    @include desktop {
        max-width: 958px;
        margin: 0 auto;
    }

    &-Picture {
        mix-blend-mode: multiply;
        height: 72px;
        background: none;
    }

    &-Heading {
        margin-block-end: 10px;
        margin-block-start: 0;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;

        @include mobile {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin-block-end: 0;
            text-align: center;
        }
    }

    &-Description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.0008em;

        .catalogue-head__title {
            margin-block-end: 10px;
            margin-block-start: 0;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;

            @include mobile {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.2px;
                margin-block-end: 0;
                text-align: center;
            }

            p {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                margin: 10px 0 0;

                @include mobile {
                    margin: 0 0 10px;
                }
            }
        }
    }

    &-subcategories {
        margin: 0 0 25px;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar {
            height: 0;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $black;
        }

        &:empty {
            display: none;
        }

        &-item {
            flex-shrink: 0;
            max-width: 136px;
            width: 136px;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }

        &-title {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.0008em;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 35px;
            border: 1px solid #D9D9D9;
            text-align: center;
        }

        &-picture {
            vertical-align: top;

            &.Image {
                padding-bottom: 0;
                height: auto;

                img {
                    position: static;
                    vertical-align: top;
                }
            }
        }
    }
}