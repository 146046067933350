/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000000;
$xryso: #F8F6F4;
$gold: #DDD7C6;
$gold1: #C4B589;
$gold2: #ccb089;
$icegrey: #F9F9F9;
$green: #5CBAB3;
$green2: #94bf92;
$foux: #EC008C;
$foux_new: #E6007E;
$grey: #D9D9D9;
$errorred: #FD0C15;
$orange: #F2994A;
$pink: #e50979;
$kylie_pink: #f9a7e7;
$lightpink: #fdebe4;
$greyF0F0F0: #F0F0F0;
$grey2D2D2D: #2D2D2D;
$greyD0D0D0: #D0D0D0;
$grey727272: #727272;
$greydddddd: #dddddd;
$black050708: #050708;
$color-grey-13: #f7f7f7;
$color-grey-7: #fbfbfb;
$color-grey-12: #d4d4d4;
$color-grey-54: #a0a0a0;
$color-grey-55: #9a9a9a;
$color-grey-E5: #E5E5E5;
$color-grey-eeeeee: #eeeeee;
$color-grey-1e1e1e: #1e1e1e;
$color-grey-A1: #A1A1A1;
$color-grey-F6F4F1: #F6F4F1;
$color-grey-F0EBE4: #F0EBE4;
$color-grey-f6f6f6: #f6f6f6;
$greyF8F8F8: #F8F8F8;
$greyE0E0E0: #E0E0E0;
$greyF2F2F2: #F2F2F2;
$black000F0F: #000F0F;
$default-primary-base-color: $black;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: $white;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'TT Norms Pro', sans-serif;
$font-blackerProTitRg: 'Blacker Pro Tit Rg', sans-serif;
$font-BlackerProDisplay: 'Blacker Pro Display', sans-serif;
$font-blackerProDisMd: 'Blacker Pro Dis Md', sans-serif;
$font-blackerProTxLt: 'Blacker Pro Tx Lt', sans-serif;
$font-blackerDisplay: 'Blacker Display', sans-serif;
$font-arial: 'Arial', sans-serif;
$font-standard-size: 62.5%;

$ease-main: .2s ease;
