/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --load-more-button-background: #{$white};
    --load-more-button-color: #{$default-primary-base-color};
    --load-more-button-border: 2px solid #{$default-secondary-base-color};
}

.CategoryListing {
    overflow: hidden;

    &-Placeholder {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
        height: 75%;
        width: 75%;
        margin: 0 auto;
    }

    &-Wrapper {
        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        display: flex;

        @include mobile {
            flex-wrap: wrap;
        }

        @include desktop {
            display: grid;
            gap: 31px;
            grid-template-columns: 236px auto;
        }

        @include wide-desktop {
            grid-template-columns: 331px auto;
        }

        @include ultra-narrow-desktop {
            gap: 15px;
        }

        > * {
            @include mobile {
                width: 100%;
            }
        }
    }

    &-Sidebar {
        @include mobile {
            width: 100%;
            margin-bottom: 22px;
        }

        @include desktop {
            flex-shrink: 0;
            grid-row: 1/6;
        }
    }

    &-Grid {
        display: grid;
        grid-gap: 11px 10px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fill, 1fr);
        flex-wrap: wrap;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 360px) and (max-width: 480px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 481px) and (max-width: 810px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include desktop {
            grid-gap: 15px 20px;
            width: 100%;
        }

        p {
            margin-bottom: 0;
        }

        .CategoryListing-LoadMoreWrapper {
            grid-column: 1/-1;
        }

        .BlogPostCard-Title {
            @media (min-width: 360px) and (max-width: 810px) {
                font-size: 14px;
            }
        }
    }

    &-Title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.2px;
        padding-top: 21px;
        margin-bottom: 12px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            padding-top: 10px;
        }
    }

    &-Description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin-bottom: 17px;

        @include desktop {
            max-width: 958px;
            margin: 0 auto;
            width: 100%;
            text-align: center;
            margin-bottom: 42px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-Search {
        @include mobile {
            display: none;
        }
    }

    &-LoadMoreWrapper {
        padding-top: 40px;
        text-align: center;
        @include mobile {
            order: 2;
            width: 100%;
        }
        flex-basis: 100%;
    }

    & &-LoadMoreButton {
        margin: auto;
        color: var(--load-more-button-color);
        background-color: var(--load-more-button-background);
        border: 2px solid $black;
        border-radius: 3px;
        font-size: 18px;
        height: 60px;
        padding: 0 30px;

        &:not([disabled]) {
            &:hover {
                background-color: $black;
                color: $white;
                height: 60px;
                padding: 0 30px;
            }
        }
    }

    .CategoryPagination {
        @include mobile {
            display: flex;
        }
    }
}
